// consumer_journey_unityCreatePlaneTicket
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://192.168.0.23:8665/doc.html#/yinzhilv-tmc/%E8%A1%8C%E7%A8%8B%E9%A2%84%E8%AE%A2/unityCreatePlaneTicketUsingPOST
const consumer_journey_unityCreatePlaneTicket = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/journey/unityCreatePlaneTicket',
        data: pParameter,
        enable_error_alert: false // 临时隐藏
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_journey_unityCreatePlaneTicket;