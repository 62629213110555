// consumer_insurance_checkFreeProduct
const __request = require(`./__request/__request_contentType_json`);
const consumer_insurance_checkFreeProduct = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/insurance/checkFreeProduct',
    data: pParameter
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_insurance_checkFreeProduct;
