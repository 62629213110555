export default {
    props: {
        violationResults: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            showMore: true,
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        resultList() {
            let res = [];
            let indexList = [];
            if (this.violationResults && this.violationResults.length > 0) {
                this.violationResults.forEach(value => {
                    if (indexList.indexOf(value.segmentSerial) == -1) {
                        indexList.push(value.segmentSerial);
                        res.push([]);
                    }
                    let index = indexList.indexOf(value.segmentSerial);
                    res[index].push(value);
                })
            }
            return res;
        }
    },
    filters: {},
    methods: {
        showOrHide() {
            this.showMore = !this.showMore
        },
        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            const __this = this
            if (column.label === '超标原因') {
                if (rowIndex === 0) {
                    return {
                        rowspan: __this.violationResults.length,
                        colspan: 1
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0
                    };
                }
            }
        },
    }
}
